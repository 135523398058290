import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {ContactService, PresenterActivity, ReassignContactAccountOverride} from "../../Shared/Services/contact.service";
import {LoadingModalComponent} from "../../Widget/Loading/loading-modal.component";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {ColDef} from "ag-grid-community";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";
import {forkJoin} from "rxjs";
import {tap} from "rxjs/operators";

@Component({
    selector: "app-reassign-contact-form",
    templateUrl: "./reassign-contact-form-component.html"
})
export class ReassignContactFormComponent extends BaseGridComponent<ReassignContactAccountOverride> implements OnInit {

    @Input()
    contactId: number;

    contactName: string;
    toContactName: string;
    toContactId: number;
    presenterRowData: PresenterActivity[];
    showLoading: boolean = false;

    activityAccountsGridOptions: any;
    contactEventRequestsRowData: any;


    @Output()
    dataUpdated = new EventEmitter<boolean>();

    contactReassignForm = this.fb.group({
        contact: this.fb.control([]),
    },{});

    columnDefs: ColDef[] = [
        {
            field: 'ActivityAccount',
            headerName: 'Account Name',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            flex: 1,
            cellRenderer: params => {
              return params.value ? params.value.Name : '';
            },
            comparator: (valueA, valueB) => { return ReassignContactFormComponent.sortActivityAccounts(valueA, valueB) },
            sort: 'asc'},
        {
            field: 'AccountCity',
            headerName: 'City',
            flex: 1,
        },
        {
            field: 'ContactId',
            headerName: 'InstNum',
            flex: 1,
        }
    ];

    presenterColumnDefs: ColDef[] = [
      {
        field: 'Date',
        headerName: 'Date',
        flex: 1,
        valueFormatter: (params) => {
          const date = new Date(params.value);
          return date.toLocaleDateString();
        },
        sort: 'desc'},
      {
        field: 'Id',
        headerName: 'Id',
        flex: 1,
      },
      {
        field: 'Name',
        headerName: 'Name',
        flex: 3,
      },
      {
        field: 'Type',
        headerName: 'Type',
        flex: 1,
      },
    ];
    private static sortActivityAccounts(valueA: any, valueB: any) {
        return valueA.Name.localeCompare(valueB.Name)
    }

    constructor(private fb: UntypedFormBuilder,
                private modalRef: BsModalRef,
                private modalService: BsModalService,
                private toastr: ToastrService,
                private contactService: ContactService,
    ) {
        super();

      this.activityAccountsGridOptions = {
        rowSelection: 'multiple',
        rowMultiSelectWithClick: true
      };

      this.contactEventRequestsRowData = {
        rowMultiSelectWithClick: false
      };


    }

    ngOnInit(): void{
        this.showLoading = true;
        this.gridOptions.rowSelection = 'multiple';
        this.gridOptions.rowMultiSelectWithClick = true;

      this.contactService.getContactById(this.contactId)
        .subscribe(c => {
          this.contactName = c.LastName + ", " + c.FirstName + " (" + c.AccountName + ")";
        });

      forkJoin([
        this.contactService.getActivityAccounts(this.contactId),
        this.contactService.getContactEventsRequests(this.contactId)
      ])
        .subscribe(([activityAccounts, eventsAndRequests]) => {
          this.rowData = activityAccounts;
          this.presenterRowData = eventsAndRequests;
          this.showLoading = false;
        });

      this.contactReassignForm.controls['contact'].valueChanges
          .subscribe(controlValues => {
              if (controlValues.length !== 1) return;
              this.toContactId = controlValues[0];
              this.gridOptions.rowMultiSelectWithClick = false;
          })

    }

    save(): void {
        this.contactService.getContactById(this.toContactId).subscribe(c => {
            this.toContactName = c.LastName + ", " + c.FirstName + " (" + c.AccountName + ")";
            let confirmRef = this.modalService.show(ConfirmModalComponent, {
                initialState: {
                    ignoreBackdropClick: true,
                    backdrop: true,
                    message: 'About to Reassign All Activities from: ' + this.contactName +  ' to: ' + this.toContactName }
            } as ModalOptions<ConfirmModalComponent>)
            confirmRef.content.action
                .subscribe(() => {
                    this.reassignContacts();
                });
        });
    }

    reassignContacts(): void {
        let loadingRef = this.modalService.show(LoadingModalComponent, {
            initialState: { message: 'Saving' },
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });

        let accountOverrideData = this.gridApi.getSelectedRows().map(a => a.ActivityAccount.Id);

        let reassignData = {
            ToContactId: this.toContactId,
            OverrideAccountIds: accountOverrideData,
        } as ReassignContact

        this.contactService.reassignContact(this.contactId, reassignData)
            .subscribe(() => {
                loadingRef.hide();
                this.toastr.success("Activities Reassigned", 'Success');
                this.dataUpdated.emit(true);
                this.modalRef.hide();
            });
    }

    setRowData() {
        this.contactReassignForm.controls['contact'].valueChanges
            .subscribe(formValue => {
                if(Number.isInteger(formValue[0])) {
                    this.contactId = formValue[0];
                    this.rowData$ = this.contactService.getActivityAccounts(this.contactId);
                }
            })
    }

    close(): void {
        this.modalRef.hide()
    }

    formValid(): boolean {
        return this.contactReassignForm.valid;
    }
}

export class ReassignContact {
    public ToContactId: number
    public OverrideAccountIds: number[]
}
