import {Injectable} from '@angular/core';
import {combineLatest, forkJoin, Observable, of} from "rxjs";
import {BaseHttpService, FileAcceptType, HttpOptions} from "./base-http.service";
import {Contact, SimpleContact} from "../../../Models/Contact";
import {ContactNote} from "../../../Models/ContactNote";
import * as _ from "lodash";
import {EmailDraftService} from "./email-draft.service";
import {EntitlementService} from "./entitlement.service";
import {ConfigService} from "./config.service";
import {Query, QueryGroup} from "../../../Services/QueryService";
import {map} from "rxjs/operators";
import * as moment from "moment";
import {ContactRecentHolding} from "../../../Models/ContactRecentHolding";

export interface ContactProfile {
    Title: string;
    MarketCapLow: number;
    MarketCapHigh: number;
    GlobalInvestor: boolean;
    PrivateInvestment: boolean;
    InvestorStyles: string[];
    DealPlayer: string[];
    Teams: string[];
    Orientations: string[];
    RoleIds: string[];
}

export interface ContactRole {
    Id: string;
    Name: string;
}

export class ReassignContactAccountOverride {
  public ActivityAccount: ActivityAccount
  public AccountCity: string
  public ContactId: number
}

export class ActivityAccount {
  public Id: number
  public Name: string
}

export class PresenterActivity{
  public Date: Date
  public Id: number
  public Name: string
  public Type: string
}

@Injectable({
    providedIn: 'root',
})
export class ContactService {

    constructor(private baseHttp: BaseHttpService,
                private configService: ConfigService,
                private emailDraftService: EmailDraftService,
                private entitlementService: EntitlementService) { }

    getContactsByQuery(query: QueryGroup[]): Observable<Contact[]> {
        const params = {
            QueryGroups: query
        };
        return this.baseHttp.postData(`/contact/query`, params);
    }

    getContactsByQueries(queries: Query[]): Observable<Contact[]> {
        return this.baseHttp.postData(`/contact/queries`, queries);
    }

    getBairdContacts(): Observable<Contact[]> {
        return this.baseHttp.getCacheOrData(`/contacts/group/baird`, 5 * 60 * 1000);
    }

    searchContacts(searchTerm: string): Observable<Contact[]> {
        return this.baseHttp.getData(`/contact;q=${searchTerm}`);
    }

    getContactProfile(contactId: number): Observable<ContactProfile> {
        return this.baseHttp.getData(`/contact/${contactId}/profile`);
    }

    // updateContactProfile(contactId: number, profile: ContactProfile): Observable<ContactProfile> {
    //     return this.baseHttp.postData(`/contact/${contactId}/profile`, profile);
    // }

    getContactSectors(contactId: number): Observable<string[]> {
        return this.baseHttp.getData(`/contact/${contactId}/sectors`);
    }

    getContactEventsRequests(contactId: number): Observable<PresenterActivity[]> {
        return this.baseHttp.getData(`/contact/${contactId}/presenteractivity`);
    }

    getContactEmployment(contactId: number): Observable<any> {
        return this.baseHttp.getData(`/contact/${contactId}/employment`);
    }

    getAvailableTeams(contactId: number): Observable<string[]> {
        return this.baseHttp.getData(`/contact/${contactId}/availableteams`);
    }

    getContactRoles(): Observable<ContactRole[]> {
        return this.baseHttp.getData(`/contact/roles`);
    }
    getContactsForAccount(query: string, prioritizeAccountId: string = null): Observable<Contact[]>{
        return this.baseHttp.getDataWithConfig(`/contactsForAccount;q=${query}`, { params: { prioritizeAccountId: prioritizeAccountId }});
    }

    getAccountSimpleContacts(accountId: number): Observable<SimpleContact[]> {
        return this.baseHttp.getData(`/account/${accountId}/simplecontacts`);
    }

    getContactById(contactId: number): Observable<Contact> {
        return this.baseHttp.getData(`/contact/id/${contactId}`);
    }

    getContactsByIds(contactIds: number[]): Observable<Contact[]> {
        let uniqueContactIds = _.uniq(contactIds);

        let contactIdsChunks$ = _.chunk(uniqueContactIds, 100)
            .map(contactIdsChunk => {
                const params = {
                    ids: _.map(contactIdsChunk, cid => cid.toString())
                };
                return this.baseHttp.getDataWithConfig(`/contacts/ids`, { params: params } );
            });

        if (contactIdsChunks$.length === 0) {
            return of([]);
        }
        return forkJoin(contactIdsChunks$).pipe(
            map((contactChunks: Contact[][]) => {
                return _.flatMap(contactChunks)
            })
        );
    }

    getContactsByEmails(emails: string[]): Observable<Contact[]> {
        const params = { e: emails };
        return this.baseHttp.getDataWithConfig(`/contacts/emails`, { params: params } );
    }

    getListsForContact(contactId: number): Observable<number[]> {
        return this.baseHttp.getData(`/contact/${contactId}/lists`)
    }

    getContactNote(contactId: number): Observable<ContactNote> {
        return this.baseHttp.getData(`/contact/${contactId}/note`);
    }

    updateContactNote(contactId: number, note: any): Observable<ContactNote> {
        return this.baseHttp.postData(`/contact/${contactId}/note`, note);
    }

    unsubscribeContactFromResearch(contactId: number): Observable<any> {
        let entitlements$ = this.entitlementService.getVendorEntitlementsForContact(contactId);
        let contact$ = this.getContactById(contactId);

        combineLatest([entitlements$, contact$]).subscribe(([entitlements, contact]) => {
            if(!_.some(entitlements, e => _.some(e.EntitlementInfo, info => info.IsSubscribed) && e.Code !== 'RES')) return;

            let entValues = _.chain(entitlements)
                .filter(e => _.some(e.EntitlementInfo, info => info.IsSubscribed) && e.Code !== 'RES')
                .map(e => {
                    let subscribedInfo = _.find(e.EntitlementInfo, info => info.IsSubscribed);
                    return e.Description + ": " + (subscribedInfo ? subscribedInfo.VendorId : '');
                })
                .join(", \n\t\t\t ")
                .value();

            let subject = `Unsubscribe: ${contact.FirstName} ${contact.LastName} (${contact.Account.Name})`;
            let body = `Turn off all third party research for:\n\n` +
                `Account: ${contact.Account.Name}\n` +
                `Name: ${contact.FirstName} ${contact.LastName}\n` +
                `Email: ${contact.Email}\n` +
                `Personal Broker: ${contact.Broker && contact.Broker.Id || ""}\n` +
                `Entitlements: ${entValues}\n` +
                `Manage Contact in Instisel: ${this.configService.getInstiselUrl()}/contact/${contact.Id}`;
            let to = ['ENT@rwbaird.com'];

            this.emailDraftService.openDraftEmail(subject, body, to);
        });

        return this.baseHttp.postData(`/contact/${contactId}/unsubscriberesearch`, {});
    }

    updateContactDetails(contact: Contact): Observable<Contact> {
        return this.baseHttp.postData(`/contact/${contact.Id}/details`, contact);
    }

    getDuplicateContacts(contact: any): Observable<any[]> {
        return this.baseHttp.postData(`/contact/${contact.Id}/duplicate`, contact);
    }

    updateContact(contact: Contact): Observable<Contact> {
        return this.baseHttp.postData(`/contact/${contact.Id}/update`, contact)
    }

    starContact(id: number): Observable<any> {
        return this.baseHttp.putData(`/contact/${id}/star`, {})
    }

    unstarContact(id: number): Observable<any> {
        return this.baseHttp.postData(`/contact/${id}/star`, {})
    }

    getContactsExport(queries: {QueryGroups: any[]}[], filename: string): Observable<any> {
        const fullFileName = `${filename}-${moment().format("YYYY-MM-DDTHH.mm.ss")}.xlsx`;
        return this.baseHttp.getFileFromPost("/contact/queries/export", fullFileName, FileAcceptType.Excel, queries);
    }

    getBrokersTradersEmailsForContacts(contactIds: number[]): Observable<string[]> {
        let params = {
            cids: contactIds.map(cid => cid.toString())
        }
        return this.baseHttp.getDataWithConfig(`/contact/tradersbrokersemails`, { params: params });
    }

    getMostRecentSubscribers(teamId: number, daysBack: number = 20): Observable<ContactRecentHolding[]> {
        return this.baseHttp.getData(`/contact/recentsubscribers/${teamId}?daysBack=${daysBack}`);
    }

    createContact(contact: any): Observable<Contact> {
        return this.baseHttp.postData(`/contact`, contact);
    }

    deleteContact(contactId: number): Observable<any> {
        return this.baseHttp.deleteData(`/contact/${contactId}`);
    }

    getContactImage(contactId: number): Observable<any> {
        return this.baseHttp.getDataWithConfig(`/contact/${contactId}/image`, {
            responseType: "blob",
        } as HttpOptions);
    }

    setContactImage(contactId: number, formData: any): Observable<any> {
        return this.baseHttp.postData(`/contact/${contactId}/image`, formData);
    }

    deleteContactImage(contactId: number): Observable<any> {
        return this.baseHttp.deleteData(`/contact/${contactId}/image`);
    }

    reassignContact(fromContactId: number, formData: any): Observable<any> {
        return this.baseHttp.postData(`/contact/${fromContactId}/reassign`, formData);
    }

    getActivityAccounts(contactId: number): Observable<ReassignContactAccountOverride[]> {
        return this.baseHttp.getData(`/contact/${contactId}/activityoverride`);
    }
}
